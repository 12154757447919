import React, { useContext, useEffect, useState, useRef } from "react";
import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import CLink from "../Components/cLinks";
import LoadPage from "../Components/loaders/loader";
import { SoundBackgroundContext } from '../modules/sound-background/sound-background';

const TemplateHome = ({ _uid }) => {

    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;

    const bandeauRef = useRef(null);

    let [currentSelect, setSelect] = useState("campus")
    let [currentSticky, setSticky] = useState(false)

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('home2'));
    }, [dispatch, isLoaded]); // isLoaded

    const { stop, setCurrentTrack } = useContext(SoundBackgroundContext);

    useEffect(() => {
        stop();
        setCurrentTrack(null);

        function eventScroll() {
            if (bandeauRef.current) {
                setSticky(bandeauRef.current.getBoundingClientRect().bottom < 0);
            }
        }

        window.addEventListener("scroll", eventScroll)
        return () => window.removeEventListener("scroll", eventScroll);
    }, [stop, setCurrentTrack]);

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}

            {
                content && <div className="templateHome2">
                    <div className="templateHome2_inner">
                        <div ref={bandeauRef} className="templateHome2_bandeau" style={{ backgroundImage: `url(${content.header.header_texte?.src}), url(${content.header.header_image?.src})` }}></div>
                        <div className="templateHome2_content">
                            <ul className={`templateHome2_select layout3 ${currentSticky ? "sticky" : ""}`}>
                                {
                                    content.campus && <li className={`templateHome2_select_item`}><button onClick={() => setSelect("campus")} className={`templateHome2_select_button templateHome2_select_button--campus ${currentSelect === "campus" ? "templateHome2_select_button--selected" : ""}`}><span className="withPicto"><span className="withTexte">{content.campus.libelle_cta}</span></span></button></li>
                                }
                                {
                                    content.salarie && <li className={`templateHome2_select_item`}><button onClick={() => setSelect("salarie")} className={`templateHome2_select_button templateHome2_select_button--salarie ${currentSelect === "salarie" ? "templateHome2_select_button--selected" : ""}`}><span className="withPicto"><span className="withTexte">{content.salarie.libelle_cta}</span></span></button></li>
                                }
                            </ul>
                            <div className="templateHome2_box">
                                {
                                    content.campus &&
                                    <>
                                        <div className={`templateHome2_box_item layout3 campus ${currentSelect === "campus" ? "templateHome2_box_item--visible" : "templateHome2_box_item--hidden"}`}>
                                            <div className="templateHome2_box_col">
                                                <p className="templateHome2_box_titre" dangerouslySetInnerHTML={{ __html: content.campus.titre }}></p>
                                                <p className="templateHome2_box_chapeau" dangerouslySetInnerHTML={{ __html: content.campus.description }}></p>
                                            </div>
                                            <div className="templateHome2_box_col">
                                                <ul className="templateHome2_entries">
                                                    {
                                                        content.campus.pushs.map((item, index) => {
                                                            return (
                                                                <li key={index} className="templateHome2_entries_item">
                                                                    <CLink url={item.cta.url} target={item.cta.target} internal={item.cta.internal}>
                                                                        <p className="templateHome2_entries_titre">{item.titre}</p>
                                                                        <p className="templateHome2_entries_texte">{item.description}</p>
                                                                        <p className="templateHome2_entries_bouton"><span className="templateHome2_entries_boutonItem" ></span></p>
                                                                    </CLink>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`templateHome2_box_frise templateHome2_box_frise--campus ${currentSelect === "campus" ? "templateHome2_box_frise--selected" : ""}`}></div>
                                    </>
                                }

                                {
                                    content.salarie &&
                                    <>
                                        <div className={`templateHome2_box_item layout3 salarie ${currentSelect === "salarie" ? "templateHome2_box_item--visible" : "templateHome2_box_item--hidden"}`}>
                                            <div className="templateHome2_box_col">
                                                <p className="templateHome2_box_titre" dangerouslySetInnerHTML={{ __html: content.salarie.titre }}></p>
                                                <p className="templateHome2_box_chapeau" dangerouslySetInnerHTML={{ __html: content.salarie.description }}></p>
                                            </div>
                                            <div className="templateHome2_box_col">
                                                <ul className="templateHome2_entries">
                                                    {
                                                        content.salarie.pushs.map((item, index) => {
                                                            return (
                                                                <li key={index} className="templateHome2_entries_item">
                                                                    <CLink url={item.cta.url} target={item.cta.target} internal={item.cta.internal}>
                                                                        <p className="templateHome2_entries_titre">{item.titre}</p>
                                                                        <p className="templateHome2_entries_texte">{item.description}</p>
                                                                        <p className="templateHome2_entries_bouton"><span className="templateHome2_entries_boutonItem"></span></p>
                                                                    </CLink>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`templateHome2_box_frise templateHome2_box_frise--salarie ${currentSelect === "salarie" ? "templateHome2_box_frise--selected" : ""}`}></div>
                                    </>
                                }
                            </div>
                            {
                                content.connecte &&
                                <div className="templateHome2_qr layout4">
                                    <div className="templateHome2_qr_col">
                                        <p className="templateHome2_qr_titre" dangerouslySetInnerHTML={{ __html: content.connecte.titre }}></p>
                                        <p className="templateHome2_qr_chapeau" dangerouslySetInnerHTML={{ __html: content.connecte.description }}></p>
                                    </div>
                                    {
                                        content.connecte.image &&
                                        <div className="templateHome2_qr_col">
                                            <img src={content.connecte.image.src} alt={content.connecte.image.alt} className="templateHome2_qr_visuel" />
                                        </div>
                                    }
                                    {
                                        content.connecte.qr_code && <img src={content.connecte.qr_code.src} alt={content.connecte.qr_code.alt} className="templateHome2_qr_code" />
                                    }
                                </div>
                            }

                        </div>
                    </div>
                </div>
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateHome;