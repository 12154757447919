import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateGlobalScrollLock, updateGlobalStoreLang } from '../../Store/action';
import { SoundBackgroundContext } from '../../modules/sound-background/sound-background';
import { TutoContext } from '../../Contexts/tutoContext'
import Navigation from '../blocks/navigation';

const Header = () => {

    let dataGlobal = require('../../api/global.json');
    // let dataGlobal = useSelector(state => state.global).global;

    // DESTRUCTURING
    const { language } = dataGlobal.header;

    // SCROLL LOCK REDUCER
    let dispatch = useDispatch();
    const { activeStep, tutoOpen } = useContext(TutoContext)
    // let scrollStore = useSelector(state => state.global).lockScroll;
    useEffect(() => {
        dispatch(updateGlobalScrollLock(false));
    }, [dispatch, dataGlobal]);

    return (
        <header className='header' style={{ zIndex: activeStep === 6 && tutoOpen ? '60' : '40' }}>
            <Navigation />
            <div className="layout header_wrapper">
                <LanguageSwitch datas={language} />
            </div>
        </header >
    )
}
export default Header;

/**
 * @component Changement de langue
 */
const LanguageSwitch = ({ datas }) => {

    const [langSelectOpen, setLangSelectOpen] = useState(false);
    const { activeStep, tutoOpen } = useContext(TutoContext)
    const langStore = useSelector(state => state.global).lang;
    const dispatch = useDispatch();

    const { stop } = useContext(SoundBackgroundContext);

    let dataGlobal = useSelector(state => state.global).global;

    window.addEventListener('click', (e) => setLangSelectOpen(false));

    const toggleList = (e) => {
        e.stopPropagation();
        setLangSelectOpen(!langSelectOpen);
    };

    useEffect(() => {
        const isContentPageLangMissing = !document.querySelector('.content-page-lang');

        // Si la classe est manquante et que la langue n'est pas française
        if (isContentPageLangMissing && langStore !== 'fr') {
            dispatch(updateGlobalStoreLang('fr'));
            stop(); // Arrêter le son si nécessaire
        }
    }, [langStore, dispatch, stop]);

    return (
        <div className={`header_lang ${activeStep === 3 && tutoOpen ? 'active-step' : ''}`}>
            <button className="header_lang-selected" onClick={(e) => toggleList(e)}>{langStore}</button>
            <ul className={`header_lang-list ${langSelectOpen ? 'open' : ''}`}>
                {datas.map((item, i) => (
                    <li key={i}
                        data-lang={item.lang}
                        data-lang-active={item.lang === langStore}
                        className="header_lang-item"
                        onClick={() => {
                            dispatch(updateGlobalStoreLang(item.lang))
                            setLangSelectOpen(false);
                            stop();
                        }}>
                        {item.text}
                    </li>
                ))}
            </ul>
            {
                dataGlobal.main &&
                <div className="tuto-explication__container">
                    <img src="/assets/img/tuto-arrow.png" alt="" />
                    <p>{dataGlobal.main.tutoriel.tutoriel_texte_3}</p>
                </div>

            }
        </div>
    );
};