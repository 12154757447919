import React, { useEffect, useContext, useState } from "react";
import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
import { Link } from "react-router-dom";
// components
import InteractiveMap from '../Components/interactiveMap/interactiveMap';
import OverlayTuto from '../Components/overlayTuto/overlayTuto';
import CollectionCard from '../Components/collectionCard/collectionCard';
import ActualitesCard from '../Components/actualitesCard/actualitesCard';
import PlayerIntro from "../Components/playerIntro/playerIntro";
import { TutoContext } from '../Contexts/tutoContext'
import LoadPage from "../Components/loaders/loader";
// modules
import { SoundBackgroundContext } from '../modules/sound-background/sound-background';


const TemplateListeCollection = ({ _uid }) => {

    let dataGlobal = useSelector(state => state.global).global;

    const [dataFetch, isLoaded] = useApi({ name: "pages", _uid });
    const { metas, content } = dataFetch;

    // Context
    const { activeStep, tutoOpen } = useContext(TutoContext);
    const { stop, setCurrentTrack } = useContext(SoundBackgroundContext);

    const [inputValue, setInputValue] = useState("");
    const [collectionArray, setCollectionArray] = useState({});
    const [collectionValid, setCollectionValid] = useState(false);
    const [activeBtn, setActiveBtn] = useState(0);
    // Dynamic Map state
    const [spotMapNumber, setSpotMapNumber] = useState(null);
    const [myCoordinates, setMyCoordinates] = useState({});

    useEffect(() => {
        stop();
        setCurrentTrack(null);
        getMyCoordinates();
    }, []);

    var spotsList = [
        { id: 1, lat: 45.77010, lng: 5.03527 },
        { id: 2, lat: 45.77026, lng: 5.03532 },
        { id: 3, lat: 45.77098, lng: 5.03541 },
        { id: 4, lat: 45.77104, lng: 5.03678 },
        { id: 5, lat: 45.77109, lng: 5.03705 },
        { id: 6, lat: 45.77097, lng: 5.03752 }
    ];

    var ECART = 0.0005;

    const getMyCoordinates = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            // let index = Math.floor(4 * Math.random());
            // setMyCoordinates({lat: spotsList[index].lat, lng: spotsList[index].lng})
            setMyCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude })
        });
    };

    const getDistanceFromArea = (myLat, myLng, posLat, posLng) => {
        var distance = Math.sqrt(Math.pow((posLat - myLat), 2) + Math.pow((posLng - myLng), 2));
        return distance;
    }

    useEffect(() => {
        let interval = null;
        if (content) {
            if (content.top.sur_place) {
                interval = setTimeout(getMyCoordinates, 5000);
                if (Object.keys(myCoordinates).length !== 0) {
                    let distanceMAX = 1000000;
                    let currentSpot = null;

                    spotsList.forEach(spot => {
                        var distance = getDistanceFromArea(myCoordinates.lat, myCoordinates.lng, spot.lat, spot.lng);
                        if (distance < ECART && distance < distanceMAX) {
                            distanceMAX = distance;
                            currentSpot = spot.id;
                        }
                    })
                    if (currentSpot) {
                        setSpotMapNumber(currentSpot);
                    }
                }
            }
        }
        return () => {
            if (interval) {
                clearInterval(interval)
            }
        };
    }, [myCoordinates, content]);

    // SUBMIT FORM -> REDIRECT PAGE IF VALID AND DISPLAY ERROR IF NOT
    const submitForm = (e) => {
        e.preventDefault();
        setInputValue('');
        const input = document.querySelector('input')
        if (collectionValid) {
            window.location.href = collectionArray[inputValue]
            input.placeholder = content.top.recherche.placeholder
            input.classList.remove('error');
        } else {
            input.placeholder = content.top.recherche.message_erreur
            input.classList.add('error');
        }
    }

    // GET ALL COLLECTIONS NUMBER
    useEffect(() => {
        if (content) {
            const collections = content.main.lst_collection;
            collections.forEach(element => {
                setCollectionArray(collectionArray => ({ ...collectionArray, [element.numero_contenu]: element.url }), {})
            });
        }
    }, [content]);

    // CHECK IF INPUT HAS A COLLECTION
    useEffect(() => {
        setCollectionValid(collectionArray[Number(inputValue)] !== undefined ? true : false);
    }, [inputValue])

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateGlobalStorePage('content-page-lang'));
    }, [dispatch, isLoaded]); // isLoaded

    const renderPage = () => (
        <>
            {metas && <Metatags {...metas} />}
            {
                content &&
                <div className={`template-home__container ${content.top.sur_place ? "template-home__container--surplace" : ""}`}>
                    {
                        tutoOpen && content.top.sur_place &&
                        <OverlayTuto />
                    }
                    <div
                        className="template-home-header"
                        style={{
                            backgroundImage: !content.top.sur_place ? `url("${content.top.image_header.src}")` : "none",
                            backgroundColor: "#0d4d63"
                        }}>
                        {
                            content.main.home_page && <Link to={content.main.home_page}><img className="template-home-header-logo" src="/assets/img/logo_transfo.svg" alt="Les voies de Transfo" /></Link>
                        }
                        {
                            !content.main.home_page && <img className="template-home-header-logo" src="/assets/img/logo_transfo.svg" alt="Les voies de Transfo" />
                        }
                        {content.top.sur_place &&
                            <InteractiveMap
                                {...content.top.recherche}
                                activeStepClass={activeStep === 1 && tutoOpen && content.top.sur_place ? 'active-step' : ''}
                                textStepTuto={dataGlobal.main.tutoriel.tutoriel_texte_1}
                                newInputValue={inputValue}
                                spotNumber={spotMapNumber}
                                submitForm={(e) => submitForm(e)}
                                changeValue={(e) => setInputValue(e.target.value)} />
                        }
                    </div>
                    <div className="template-home-content layout">
                        <h2 className="template-home-content-title">{content.main.titre}</h2>
                        <p className="template-home-content-text">{content.main.description}</p>
                        {
                            content.main.intro.audio &&
                            <PlayerIntro url={content.main.intro.audio.src} />
                        }
                        <ActualitesCard
                            data={content.main.push_actualite}
                        />
                        {content.main.choix.cta_choix1.url && content.main.choix.cta_choix2.url && content.main.choix.cta_choix1.title && content.main.choix.cta_choix2.title &&
                            <div className="template-home-content-parcours">
                                <div className={`template-home-content-parcours-btn ${content.main.current === "/liste-collections-decouverte-a-distance" || content.main.current === "/liste-collections-decouverte-sur-place" ? "active" : ""} ${activeBtn === 1 ? "hover" : ""}`}>
                                    <a href={content.main.choix.cta_choix1.url} target={content.main.choix.cta_choix1.target}>
                                        <p>{content.main.choix.cta_choix1.title}</p>
                                        <div className={`parcours-overlayInfos ${activeBtn === 1 ? "active" : ""}`}>
                                            <img src='/assets/img/tuto-arrow.png' alt="arrow" />
                                            <p>{content.main.choix.cta_choix1.info_bulle}</p>
                                        </div>
                                    </a>
                                    <span onMouseEnter={() => setActiveBtn(1)} onMouseLeave={() => setActiveBtn(0)}><img src="https://img.icons8.com/material-outlined/17/null/info--v1.png" alt="" /></span>
                                </div>
                                <div className={`template-home-content-parcours-btn ${content.main.current === "/liste-collections-expert-a-distance" || content.main.current === "/liste-collection-expert-sur-place" ? "active" : ""} ${activeBtn === 2 ? "hover" : ""}`}>
                                    <a href={content.main.choix.cta_choix2.url} target={content.main.choix.cta_choix2.target} >
                                        <p>{content.main.choix.cta_choix2.title}</p>
                                        <div className={`parcours-overlayInfos ${activeBtn === 2 ? "active" : ""}`}>
                                            <img src='/assets/img/tuto-arrow.png' alt="arrow" />
                                            <p>{content.main.choix.cta_choix2.info_bulle}</p>
                                        </div>
                                    </a>
                                    <span onMouseEnter={() => setActiveBtn(2)} onMouseLeave={() => setActiveBtn(0)}><img src="https://img.icons8.com/material-outlined/17/null/info--v1.png" alt="" /></span>
                                </div>
                            </div>}
                        <div className={`template-home-content-collections ${activeStep === 2 && tutoOpen && content.top.sur_place ? 'active-step' : ''}`}>
                            <ul className={`collection-list order-${spotMapNumber}`}>
                                {
                                    content.main.lst_collection.length > 0 &&
                                    content.main.lst_collection.map((collection, key) => {
                                        return (
                                            <CollectionCard
                                                key={key}
                                                collection_number={key + 1}
                                                {...collection}
                                            />
                                        )
                                    })
                                }
                            </ul>
                            {content.top.sur_place && dataGlobal.main.tutoriel &&
                                <div className="tuto-explication__container">
                                    <img src="/assets/img/tuto-arrow.png" alt="" />
                                    <p>{dataGlobal.main.tutoriel.tutoriel_texte_2}</p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`template-home-overlay ${activeBtn !== 0 ? 'active' : ''}`} />
                </div>
            }
        </>
    )
    return isLoaded ? renderPage() : <LoadPage />
}

export default TemplateListeCollection;